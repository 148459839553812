import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import HeadSection from 'components/section/course/headSection'
import AboutSection from 'components/section/junior/aboutSection'
import Secret1Section from 'components/section/junior/secret1Section'
import Secret2Section from 'components/section/course/secret2Section'
import LessonSection from 'components/section/junior/lessonSection'
import PriceSection from 'components/section/course/priceSection'
import RecommendSection from 'components/section/junior/recommendSection'
import CallToActionSection from 'components/section/common/callToActionSection'
import { RoomStudy, RoomTeach, RoomTest, RoomTeacher } from 'components/icon/index'
import {} from 'variables/_index'

export interface JuniorCourseTemplateProps {
  readonly noProps?: string
}

type JuniorCourseTemplatePropsClassName = JuniorCourseTemplateProps & {
  readonly className: string
}

function Component({ className }: JuniorCourseTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection
        imageElement={
          <StaticImage src="../assets/images/junior/mv.jpg" alt="" placeholder="none" />
        }
        title="中学生コース"
        leadElement={
          <>
            学校の授業についていけない、
            <br />
            勉強が苦手というお子さまも
            <br />
            成績アップを目指せるコースです。
          </>
        }
      />
      <AboutSection />
      <CallToActionSection
        contactId="cta_content1_trial"
        isDisplayDocument={false}
        isDisplayAbout={false}
      />
      <Secret1Section />
      <Secret2Section
        netRooms={{
          netRoomItems: [
            {
              lineHeading: {
                mainText: '1｜スタディルーム',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomStudy />,
              },
              time: '月～土・18:00～21:40',
              description: '宿題やテスト勉強ができる自習室。いつも先生が見守っています。',
            },
            {
              lineHeading: {
                mainText: '2｜解説ルーム',
                subText: '（予約制）',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomTeach />,
              },
              description: '勉強でわからないところを先生に質問できます。学校の宿題もOK。',
            },
            {
              lineHeading: {
                mainText: '3｜単元テストルーム',
                subText: '（予約制）',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomTest />,
              },
              description:
                '授業ごとにある単元テストに合格できなくても、ここで再チャレンジできます。',
            },
            {
              lineHeading: {
                mainText: '4｜担任ルーム',
                subText: '（予約制）',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomTeacher />,
              },
              description:
                'お子さまが勉強で迷った時には、いつでも担任の先生と個別コーチング。',
            },
          ],
        }}
      />
      <CallToActionSection contactId="cta_content3_trial" isDisplayDocument={false} />
      <LessonSection />
      <PriceSection
        priceBoxes={[
          {
            headText: '中学1年生・2年生',
            bodyText1: <>週1回｜14,400円／月</>,
          },
          {
            headText: '中学3年生',
            bodyText1: <>週1回｜16,800円／月</>,
            subTexts: ['※ 教材費 1教科 2,400円〜', '※ 諸経費 3,200円／月'],
          },
        ]}
      />
      <CallToActionSection contactId="cta_content4_trial" isDisplayDocument={false} />
      <RecommendSection />
    </div>
  )
}

const StyledComponent = styled(Component)<JuniorCourseTemplatePropsClassName>``

export const JuniorCourseTemplate: (props: JuniorCourseTemplateProps) => JSX.Element =
  StyledComponent

export default JuniorCourseTemplate
