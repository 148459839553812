import React from 'react'
import styled from 'styled-components'
import { UnderBarHeading, ButtonSmall } from 'components/atoms/index'
import { LessonBox, PriceBox } from 'components/group/index'
import {
  rem,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'
import { siteStructure } from 'utils/site'
export interface LessonProps {
  readonly noProps?: string
}

type LessonPropsClassName = LessonProps & {
  readonly className: string
}

function Component({ className }: LessonPropsClassName) {
  return (
    <div className={className}>
      <div className="e-lesson-group">
        <div className="e-inner">
          <div className="e-head">
            <UnderBarHeading text="授業の概要" headingLevel="h2" />
          </div>
          <div className="e-lesson-items">
            <div className="e-lesson-item">
              <LessonBox
                headText={<>教科</>}
                bodyText1={<>数学｜英語｜国語</>}
                headingLevel="h3"
              />
            </div>
            <div className="e-lesson-item">
              <LessonBox
                headText={<>時間割</>}
                bodyText1={<>80分｜講師1人・生徒2人</>}
                bodyText2={
                  <>
                    月～土曜日
                    <br />
                    1｜ 17：30～18：50
                    <br />
                    2｜ 19：00～20：20
                    <br />
                    3｜ 20：30～21：50
                  </>
                }
                isAlignLeft={true}
                headingLevel="h3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<LessonPropsClassName>`
  padding-top: ${rem(160 / 2)};

  > .e-lesson-group {
    > .e-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-left: ${rem(40 / 2)};
      padding-right: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        width: ${innerWidth.md};
        padding-left: ${rem(innerPadding.md + innerDummyPadding.md)};
        padding-right: ${rem(innerPadding.md + innerDummyPadding.md)};
      }

      > .e-head {
        text-align: center;
      }

      > .e-lesson-items {
        margin-top: ${rem(60 / 2)};

        > .e-lesson-item {
          &:nth-child(n + 2) {
            margin-top: ${rem(40 / 2)};
          }
        }
      }
    }
  }
`

export const Lesson: (props: LessonProps) => JSX.Element = StyledComponent

export default Lesson
