import React from 'react'
import styled from 'styled-components'
import StudyMethod from './study-method'
import { rem } from 'variables/_index'

export interface Secret1Props {
  readonly noProps?: string
}

type Secret1PropsClassName = Secret1Props & {
  readonly className: string
}

function Component({ className }: Secret1PropsClassName) {
  return (
    <div className={className}>
      <StudyMethod />
    </div>
  )
}

const StyledComponent = styled(Component)<Secret1PropsClassName>`
  padding-top: ${rem(200 / 2)};
`

export const Secret1: (props: Secret1Props) => JSX.Element = StyledComponent

export default Secret1
