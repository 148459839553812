import React from 'react'
import styled from 'styled-components'
import { LineHeading, StudyMethodBox } from 'components/atoms/index'
import { rem, breakpoints } from 'variables/_index'
import { Method1 } from 'components/icon'

export interface StudyMethodPoint1Props {
  readonly noProps?: number
}

type StudyMethodPoint1PropsClassName = StudyMethodPoint1Props & {
  readonly className: string
}

function Component({ className }: StudyMethodPoint1PropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <LineHeading mainText="1｜勉強が止まらなくなる︕" headingLevel="h5" />
      </div>
      <div className="e-body">
        <div className="e-box">
          <StudyMethodBox
            lineText1="学校授業の先取りサイクル"
            lineText2="単元テスト"
          />
        </div>
        <div className="e-image">
          <Method1 className="e-icon" />
        </div>
        <div className="e-text">
          一番大切な学校の授業が理解できることは、お子さまの自信につながります。
          <br />
          勉強嫌いの原因となるつまずきやあきらめを、予習型の通常授業で防ぎます。
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StudyMethodPoint1PropsClassName>`
  > .e-head {
  }

  > .e-body {
    padding: ${rem(80 / 2)} ${rem(40 / 2)} ${rem(160 / 2)};

    > .e-box {
      padding-left: ${rem(24 / 2)};
      padding-right: ${rem(24 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        padding-left: 0;
        padding-right: 0;
      }
    }

    > .e-image {
      margin-top: ${rem(60 / 2)};
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @media ${breakpoints.lg} {
        min-height: ${rem(210)};
      }

      > .e-icon {
        width: ${rem(420 / 2)};
        height: ${rem(420 / 2)};
        max-width: 100%;
      }
    }

    > .e-text {
      margin-top: ${rem(60 / 2)};
      font-size: 1rem;
      line-height: 2;
    }
  }
`

export const StudyMethodPoint1: (props: StudyMethodPoint1Props) => JSX.Element =
  StyledComponent

export default StudyMethodPoint1
