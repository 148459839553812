import React from 'react'
import styled from 'styled-components'
import {
  fontFamily,
  color,
  innerWidth,
  rem,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'
export interface RecommendProps {
  readonly noProps?: string
}

type RecommendPropsClassName = RecommendProps & {
  readonly className: string
}

function Component({ className }: RecommendPropsClassName) {
  return (
    <div className={className}>
      <h2 className="e-head">こんなお子さまにおすすめ</h2>
      <div className="e-body">
        <div className="e-inner">
          <div className="e-icon">
            <svg
              className="e-icon-smile"
              height="90.602"
              viewBox="0 0 90 90.602"
              width="90"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#00c8ff" transform="translate(-257.6 -228.699)">
                <path d="m302.6 319.3a45.3 45.3 0 1 1 45-45.3 45.2 45.2 0 0 1 -45 45.3zm0-81.2a35.9 35.9 0 1 0 35.6 35.9c-.1-19.8-16-35.9-35.6-35.9z" />
                <path d="m294.2 265.6a5.2 5.2 0 1 1 -5.2-5.3 5.25 5.25 0 0 1 5.2 5.3z" />
                <path d="m321.4 265.6a5.2 5.2 0 1 1 -5.2-5.3 5.25 5.25 0 0 1 5.2 5.3z" />
                <path d="m302.5 297a22.335 22.335 0 0 1 -16.4-7 3.688 3.688 0 1 1 5.6-4.8 15.063 15.063 0 0 0 10.8 4.4 17.025 17.025 0 0 0 11.3-4.5 3.68 3.68 0 0 1 5.4 5 24.05 24.05 0 0 1 -16.7 6.9z" />
              </g>
            </svg>
          </div>
          <ul className="e-lists">
            <li className="e-list">テストの平均点が30点～60点</li>
            <li className="e-list">一人では勉強のやり方がわからない</li>
            <li className="e-list">部活や習い事をがんばっている</li>
            <li className="e-list">新しく何かを創り出すことが好き</li>
            <li className="e-list">学校があまり好きではない</li>
          </ul>
          <div className="e-no-box">
            <h3 className="e-no-head">おすすめできないお子さま</h3>
            <ul className="e-no-lists">
              <li className="e-no-list">
                <div className="e-no-list-text">
                  <svg
                    className="e-icon-cross"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m283.4 230.482-2.082-2.082-9.9 9.936-9.936-9.936-2.082 2.082 9.936 9.936-9.936 9.9 2.082 2.082 9.936-9.936 9.9 9.936 2.082-2.082-9.936-9.9z"
                      fill="#00c8ff"
                      transform="translate(-259.4 -228.4)"
                    />
                  </svg>
                  いつも周りに友だちがいないと不安
                </div>
              </li>
              <li className="e-no-list">
                <div className="e-no-list-text">
                  <svg
                    className="e-icon-cross"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m283.4 230.482-2.082-2.082-9.9 9.936-9.936-9.936-2.082 2.082 9.936 9.936-9.936 9.9 2.082 2.082 9.936-9.936 9.9 9.936 2.082-2.082-9.936-9.9z"
                      fill="#00c8ff"
                      transform="translate(-259.4 -228.4)"
                    />
                  </svg>
                  有名進学校に進みたい
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<RecommendPropsClassName>`
  padding-top: ${rem(160 / 2)};
  padding-bottom: ${rem(160 / 2)};

  > .e-head {
    font-family: ${fontFamily.zenKaku};
    font-size: 1.563rem;
    font-weight: 500;
    text-align: center;
    color: ${color.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(30)};
    }
  }

  > .e-body {
    margin-top: ${rem(40 / 2)};
    padding-top: ${rem(60 / 2)};
    padding-bottom: ${rem(60 / 2)};
    background-color: ${color.mainBackground};

    > .e-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${innerWidth.lg};
        padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
        padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      }

      > .e-icon {
        text-align: center;

        > .e-icon-smile {
          width: ${rem(90 / 2)};
          height: ${rem(90 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(60)};
            height: ${rem(60)};
          }
        }
      }

      > .e-lists {
        margin: ${rem(30 / 2)} 0 0;
        padding: 0 ${rem(40 / 2)};
        list-style: none;

        @media ${breakpoints.lg} {
          width: ${rem(800)};
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;
        }

        > .e-list {
          padding: 0.2rem;
          background-color: #fff;
          font-size: 1rem;
          line-height: 1.75;
          text-align: center;
          color: ${color.main};

          &:nth-child(n + 2) {
            margin-top: ${rem(20 / 2)};
          }

          @media ${breakpoints.lg} {
            font-size: ${rem(20)};
          }
        }
      }

      > .e-no-box {
        margin-top: ${rem(80 / 2)};
        padding: ${rem(20 / 2)} ${rem(40 / 2)} ${rem(40 / 2)};
        background-color: #fff;

        @media ${breakpoints.lg} {
          width: ${rem(800)};
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        > .e-no-head {
          font-family: ${fontFamily.zenKaku};
          font-size: ${rem(38 / 2)};
          font-weight: 500;
          line-height: 1.447;
          text-align: center;
          color: ${color.main};
        }

        > .e-no-lists {
          margin: ${rem(20 / 2)} 0 0;
          padding: 0;
          list-style: none;

          > .e-no-list {
            text-align: center;
            margin: 0;
            padding: 0;

            > .e-no-list-text {
              display: inline-block;
              padding-left: ${rem((10 + 24) / 2)}; // 余白 + icon-crossの幅
              font-size: ${rem(32 / 2)};
              line-height: 1.75;
              color: ${color.main};
              position: relative;

              > .e-icon-cross {
                width: ${rem(24 / 2)};
                height: ${rem(24 / 2)};
                margin-top: 1px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

            &:nth-child(n + 2) {
              margin-top: ${rem(10 / 2)};
            }
          }
        }
      }
    }
  }
`

export const Recommend: (props: RecommendProps) => JSX.Element = StyledComponent

export default Recommend
