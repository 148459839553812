import React from 'react'
import styled from 'styled-components'
import { LeadBoxLarge } from 'components/atoms/index'
import { LogoBox, ILogo, StarGirl } from 'components/group/index'
import {
  color,
  fontFamily,
  rem,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const logo11: ILogo = {
    textEnglish: 'STUDY METHOD',
    textJapanese: 'フィットスタディーメソッド',
  }

  const logo21: ILogo = {
    textEnglish: 'NET ROOMS',
    textJapanese: 'フィットネットルームス',
  }

  const logo22: ILogo = {
    textEnglish: 'ONE TEAM',
    textJapanese: 'フィットワンチーム',
  }
  return (
    <div className={className}>
      <div className="e-head">
        <LeadBoxLarge leadText={<>成績がアップする</>} headingLevel="h2" />
      </div>
      <div className="e-illustration">
        <StarGirl />
      </div>
      <div className="e-inner">
        <div className="e-calc">
          <div className="e-calc-item">
            <div className="e-calc-head">成績があがる仕組み</div>
            <div className="e-calc-box">
              <LogoBox logo1={logo11} />
            </div>
          </div>
          <div className="e-calc-plus">＋</div>
          <div className="e-calc-item">
            <div className="e-calc-head">つまずかないサポート体制</div>
            <div className="e-calc-box">
              <LogoBox logo1={logo21} logo2={logo22} />
            </div>
          </div>
        </div>
        <div className="e-calc-result">= 一人ひとりの成績アップ！</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  padding-top: 3.812rem;
  padding-bottom: 5rem;

  > .e-head {
    text-align: center;
  }

  > .e-illustration {
    margin-top: 1.25rem;

    img {
    }
  }

  > .e-inner {
    margin-top: 1.25rem;
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-calc {
      @media ${breakpoints.lg} {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      > .e-calc-item {
        @media ${breakpoints.lg} {
        }

        > .e-calc-head {
          font-family: ${fontFamily.zenKaku};
          font-size: 1.438rem;
          font-weight: 500;
          text-align: center;
          color: ${color.main};
        }

        > .e-calc-box {
          margin-top: 0.625rem;
          text-align: center;
        }
      }

      > .e-calc-plus {
        margin-top: ${rem(24 / 2)};
        margin-bottom: ${rem(24 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-size: 2.031rem;
        font-weight: 500;
        text-align: center;
        color: ${color.main};

        @media ${breakpoints.lg} {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: ${rem(50)};
          margin-left: ${rem(24)};
          margin-right: ${rem(24)};
          text-align: center;
        }
      }
    }

    > .e-calc-result {
      margin-top: 1.25rem;
      font-family: ${fontFamily.zenKaku};
      font-size: 1.438rem;
      font-weight: 500;
      text-align: center;
      color: ${color.main};
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element =
  StyledComponent

export default AboutSection
