import React from 'react'
import styled from 'styled-components'
import { StudyMethod } from 'components/group/index'
import { color, rem, breakpoints } from 'variables/_index'

export interface StudyMethodLeadProps {
  readonly noProps?: number
}

type StudyMethodLeadPropsClassName = StudyMethodLeadProps & {
  readonly className: string
}

function Component({ className }: StudyMethodLeadPropsClassName) {
  return (
    <div className={className}>
      <div className="e-method">
        <StudyMethod />
      </div>
      <div className="e-text">
        <strong>3つの授業</strong>と<strong>3つのテスト</strong>
        をくり返すことで、理解できていない単元を見つけ、成績アップを実現していきます。
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StudyMethodLeadPropsClassName>`
  > .e-method {
    padding-left: ${rem(24 / 2)};
    padding-right: ${rem(24 / 2)};

    @media ${breakpoints.lg} {
      display: none;
    }
  }

  > .e-text {
    margin-top: ${rem(60 / 2)};
    font-size: 1rem;
    line-height: 2;

    > strong {
      color: ${color.main};
      font-weight: inherit;
    }

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      text-align: center;
    }
  }
`

export const StudyMethodLead: (props: StudyMethodLeadProps) => JSX.Element =
  StyledComponent

export default StudyMethodLead
