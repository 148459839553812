import React from 'react'
import styled from 'styled-components'
import { ButtonVector } from 'components/atoms/index'
import { color, rem, fontFamily, breakpoints } from 'variables/_index'
import { siteStructure } from 'utils/site'

export interface StudyMethodResultProps {
  readonly noProps?: number
}

type StudyMethodResultPropsClassName = StudyMethodResultProps & {
  readonly className: string
}

function Component({ className }: StudyMethodResultPropsClassName) {
  return (
    <div className={className}>
      <div className="e-bubble">
        <div className="e-bubble-text">
          <strong>3つの授業</strong>+<strong>3つのテスト</strong>
          <br className="u-hidden-lg" />は<br className="u-hidden-lg" />
          月額受講料で受講できます
        </div>
      </div>
      <div className="e-button">
        <ButtonVector
          id="cta_content2_trial"
          href={siteStructure.experience1.path}
          value={
            <>
              <strong>無料体験</strong>してみる
            </>
          }
        />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StudyMethodResultPropsClassName>`
  > .e-bubble {
    display: flex;
    align-items: center;
    justify-content: center;

    > .e-bubble-text {
      font-family: ${fontFamily.zenKaku};
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.28;
      text-align: center;
      text-indent: 1em;
      white-space: nowrap;

      @media ${breakpoints.lg} {
        text-indent: 0;
        font-size: ${rem(24)};
      }

      > strong {
        color: ${color.main};
        font-weight: inherit;
      }

      > span {
        display: inline-block;
      }
    }

    &::before,
    &::after {
      content: '';
      flex-shrink: 0;
      display: inline-block;
      width: 1.5px;
      height: ${rem(150 / 2)};
      background-color: ${color.main};

      @media ${breakpoints.lg} {
        height: ${rem(50)};
      }
    }

    &::before {
      margin-right: ${rem(20)};
      transform: rotate(-20deg);
    }

    &::after {
      margin-left: ${rem(20)};
      transform: rotate(20deg);
    }
  }

  > .e-button {
    margin-top: 1.875rem;
    text-align: center;
  }
`

export const StudyMethodResult: (props: StudyMethodResultProps) => JSX.Element =
  StyledComponent

export default StudyMethodResult
