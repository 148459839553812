import React from 'react'
import styled from 'styled-components'
import { LineHeading, StudyMethodBox } from 'components/atoms/index'
import { rem, breakpoints } from 'variables/_index'
import { Method3 } from 'components/icon/index'

export interface StudyMethodPoint3Props {
  readonly noProps?: number
}

type StudyMethodPoint3PropsClassName = StudyMethodPoint3Props & {
  readonly className: string
}

function Component({ className }: StudyMethodPoint3PropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <LineHeading mainText="3｜入学からの弱点を総点検︕" headingLevel="h5" />
      </div>
      <div className="e-body">
        <div className="e-box">
          <StudyMethodBox lineText1="総復習講座" lineText2="総穴埋めテスト" />
        </div>
        <div className="e-image">
          <Method3 className="e-icon" />
        </div>
        <div className="e-text">
          春・夏・冬の講習時期には、基礎学力をアップするための総復習を実施。
          <br />
          毎回入学からすべての単元を総復習することで、成績アップの土台を固めます。
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StudyMethodPoint3PropsClassName>`
  > .e-head {
  }

  > .e-body {
    padding: ${rem(80 / 2)} ${rem(40 / 2)} ${rem(160 / 2)};

    > .e-box {
      padding-left: ${rem(24 / 2)};
      padding-right: ${rem(24 / 2)};
      text-align: center;
    }

    > .e-image {
      margin-top: ${rem(60 / 2)};
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @media ${breakpoints.lg} {
        min-height: ${rem(210)};
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > .e-icon {
        width: ${rem(500.4 / 2)};
        max-width: 100%;
        height: ${rem(255.6 / 2)};
        max-width: 100%;
      }
    }

    > .e-text {
      margin-top: ${rem(60 / 2)};
      font-size: 1rem;
      line-height: 2;
    }
  }
`

export const StudyMethodPoint3: (props: StudyMethodPoint3Props) => JSX.Element =
  StyledComponent

export default StudyMethodPoint3
