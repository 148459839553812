import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { LogoHeading } from 'components/atoms/index'
import { SecretHead } from 'components/group/index'
import StudyMethodLead from './lead'
import StudyMethodPoint1 from './point1'
import StudyMethodPoint2 from './point2'
import StudyMethodPoint3 from './point3'
import StudyMethodResult from './result'
import {
  color,
  innerWidth,
  rem,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface StudyMethodProps {
  readonly noProps?: string
}

type StudyMethodPropsClassName = StudyMethodProps & {
  readonly className: string
}

function Component({ className }: StudyMethodPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <SecretHead
          secretElement={
            <StaticImage
              src="../../../../assets/images/junior/icon-secret1.png"
              alt="secret1 秘密"
            />
          }
          lead="「わからない」を発見！"
          title="成績があがる仕組み"
          headingLevel="h3"
        />
      </div>
      <div className="e-body">
        <div className="e-body-head">
          <LogoHeading
            textEnglish="STUDY METHOD"
            textJapanese="フィットスタディーメソッド"
            headingLevel="h4"
          />
        </div>
        <div className="e-inner">
          <div className="e-lead-area">
            <StudyMethodLead />
          </div>
          <div className="e-secret-items">
            <div className="e-secret-item">
              <StudyMethodPoint1 />
            </div>
            <div className="e-secret-item">
              <StudyMethodPoint2 />
            </div>
            <div className="e-secret-item">
              <StudyMethodPoint3 />
            </div>
          </div>
          <div className="e-secret-result">
            <StudyMethodResult />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StudyMethodPropsClassName>`
  > .e-head {
  }

  > .e-body {
    margin-top: ${rem(80 / 2)};
    padding-top: ${rem(120 / 2)};
    padding-bottom: 1.875rem;
    background-color: ${color.mainBackground};

    > .e-body-head {
    }

    > .e-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-top: ${rem(40 / 2)};
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;

      @media ${breakpoints.lg} {
        width: ${innerWidth.lg};
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
      }

      > .e-lead-area {
        padding-top: ${rem(80 / 2)};
        padding-left: ${rem(50 / 2)};
        padding-right: ${rem(50 / 2)};

        @media ${breakpoints.lg} {
          padding-top: ${rem(80 / 2 + 60 / 2)};
        }
      }

      > .e-secret-items {
        padding-top: ${rem(160 / 2)};

        @media ${breakpoints.lg} {
          padding-top: ${rem(50)};
          display: flex;
        }

        > .e-secret-item {
          @media ${breakpoints.lg} {
            width: calc(33% - ${rem(24)} * 2 / 3);

            &:nth-child(n + 2) {
              margin-left: ${rem(24)};
            }
          }
        }
      }

      > .e-secret-result {
        padding-top: calc(${rem(80 / 2)} + ${rem(20 / 2)});
        padding-left: ${rem(50 / 2)};
        padding-right: ${rem(50 / 2)};
        padding-bottom: ${rem(80 / 2)};
        border-top: 1px solid ${color.main};
        position: relative;

        @media ${breakpoints.lg} {
          margin-left: ${rem(-30)};
          margin-right: ${rem(-30)};
        }

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: ${color.main};
          position: absolute;
          top: ${rem(20 / 2)};
          left: 0;
        }
      }
    }
  }
`

export const StudyMethod: (props: StudyMethodProps) => JSX.Element = StyledComponent

export default StudyMethod
