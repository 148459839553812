import React from 'react'
import Seo from 'components/seo'
import Layout from 'layout/base'
import JuniorCourseTemplate from 'templates/juniorCourseTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface CourseJuniorPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const CourseJuniorPage = ({ pageContext, location }: CourseJuniorPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'course'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`中学生コース${globalTitle(location.pathname)}`}
        description="「成績があがる仕組み」と「つまづかないサポート体制」で、勉強が苦手なお子さまも成績アップを目指せるコースです。"
        path={location.pathname}
      />
      <JuniorCourseTemplate />
    </Layout>
  )
}

export default CourseJuniorPage
